<template>
  <Header
    :headerText="$t('main.fingerprints_stored')"
    :goBack="goBack"
    :goHome="goHome"
    :showBars="true"
    :allBarsActive="true"
  />
  <div v-if="!isOnEdition" class="d-flex flex-column justify-content-around align-items-center py-4 h-100">
    <div class="he-ui-stored-fingerprints-title he-ui-font-normal-bold">{{ $t('main.select_fingerprints_to_edit') }}</div>
    <div
      @mouseleave="mouseOutHands"
      class="he-ui-stored-fingerprints-hands-wrapper bg-white d-flex align-items-center justify-content-around position-relative w-90">
      <div
        v-for="(n, finger) in 5"
        :key="finger"
        class="he-ui-stored-fingerprints-hand-img"
        @click="(e) => onSelectHand(e, finger, HANDS.LEFT.index)"
        @mouseover.stop.prevent="mouseOver"
        @mouseout.stop.prevent="mouseOut">
        <img
          :src="getHands(HANDS.LEFT.index, finger, savedFingers)"
          :alt="`left-hand-${finger}`" />
      </div>
      <div
        v-for="finger in [...Array(10).keys()].slice(5)"
        :key="finger"
        class="he-ui-stored-fingerprints-hand-img"
        @click="(e) => onSelectHand(e, finger, HANDS.RIGHT.index)"
        @mouseover.stop.prevent="mouseOver"
        @mouseout.stop.prevent="mouseOut">
        <img
          :src="getHands(HANDS.RIGHT.index, finger, savedFingers)"
          :alt="`right-hand-${index}`" />
      </div>
      <div
        v-show="showItemMark"
        ref="itemMark"
        class="he-ui-item-mark" />
    </div>
    <div
      ref="infoBoxContainer"
      class="he-ui-infobox-container"
    >
      <info-box
        v-if="selectedHand !== null"
        class="he-ui-info-box"
        status="indications"
        :hand="selectedHand"
        :finger="selectedFinger" />
    </div>
    <div
      class="d-flex w-100 justify-content-end">
      <div class="d-flex">
        <MainButton
          v-if="showDiscardButton"
          class="me-4"
          template="white"
          rounded
          uppercase
          :text="$t('main.discard')"
          :onClick="onDiscard" />
        <MainButton
          class="me-4"
          :class="{'btn-disabled': !isFingerSelected}"
          template="white"
          rounded
          uppercase
          :disabled="!isFingerSelected"
          :text="$t('main.edit')"
          :onClick="onEdit" />
        <MainButton
          v-if="areThereOneFingerOfEachHand"
          template="blue"
          rounded
          uppercase
          :text="$t('main.save')"
          :onClick="onSave" />
      </div>
    </div>
  </div>

  <!-- Edition view -->
  <DefaultContainer
    v-if="isOnEdition"
    col1='col-5'
    col2='col-7'
  >
    <template #col1>
      <section class="col1 d-flex flex-column align-items-center justify-content-center h-100">
        <info-box
          class="he-ui-info-box mb-5"
          status="indications"
          :hand="selectedHand"
          :finger="selectedFinger"
        />
        <Hand
          :selectedHand="selectedHand"
          :selectedFinger="selectedFinger"
          :showNumberOfFingers="false"
          :showAllFingerprints="true"
          :showNotActiveFingerprints="false"
          :isNewPatient="false"
        />
      </section>
    </template>
    <template #col2>
      <section class="col2 d-flex flex-column he-ui-scanning-section">
        <scanning-results
          ref="scanningResultsComponent"
          class="he-ui-scan-main-finger-wrapper"
          :isEditPage="true"
          :status="status"
          :hand="selectedHand"
          :finger="selectedFinger"
          :onRegistering="isNewPatient"
          :saveDisabled="imageData === null"
          @scan="onScan"
          @cancel="onCancel"
          @imageCaptured="onImageCaptured"
        />
      </section>
    </template>
  </DefaultContainer>
  <!-- end Edition view -->

  <portal to='modal-place'>
      <DiscardFingerprintModal
        v-if="showDiscardFingerprintModal"
        :title="$t('main.discard_fingerprint')"
        :subtitle="$t('main.discard_fingerprint_confirmation')"
        :hand="selectedHand"
        :finger="selectedFinger"
        @close="closeDiscardModal"
        @discard="afterDiscard"
      />
  </portal>
  <portal to="modal-place">
    <SuccesModal
      v-if="showSuccessModal"
      :patientId="patientId"
      :isNewPatient="isNew"
      :isAlreadyRegistered="isAlreadyRegisteredPatient"
      showButton="true"
      :message="$t('main.accept')"
      :onClicked="closeSuccessModal"/>
  </portal>
  <portal to="modal-place">
    <LeaveRegister
      v-if="showLeavingModal"
      :buttons="leavingModalButtons"
      :isNewPatient=true />
  </portal>
</template>

<script>
import { ERR_CODE_PATIENT_ALREADY_REGISTERED, FINGERS_DATA, HANDS } from '@/constants/constants'
import { MAIN_PAGE_ROUTE_NAME } from '@/router/routes'
import { mapActions, mapMutations } from 'vuex'
import DefaultContainer from '@/components/DefaultContainer'
import DiscardFingerprintModal from '@/components/DiscardFingerprintModal'
import Hand from '@/components/Hand'
import Header from '@/components/Header'
import InfoBox from '@/components/InfoBox'
import LeaveRegister from '@/components/LeaveRegister'
import MainButton from '@/components/MainButton'
import ScanningResults from '@/components/ScanningResults'
import SuccesModal from '@/components/SuccesModal'
import fingerprintReader from '@/composition/fingerprintReader'
import handsCompositionFunction from '@/composition/handsCompositionFunction'
import mixins from '@/composition/mixins'

const PX_STR = 'px'

export default {
  name: 'StoredFingerprints',
  components: {
    DefaultContainer,
    DiscardFingerprintModal,
    Hand,
    Header,
    InfoBox,
    LeaveRegister,
    MainButton,
    ScanningResults,
    SuccesModal
  },
  setup () {
    const { getHands } = handsCompositionFunction()
    const { captureFingerprint, getSerialNumber, stopCapture } = fingerprintReader()
    const { sendDataToParentClient } = mixins()

    return {
      captureFingerprint,
      getHands,
      getSerialNumber,
      sendDataToParentClient,
      stopCapture
    }
  },
  data () {
    return {
      selectedFinger: null,
      selectedHand: null,
      HANDS,
      showItemMark: false,
      initialLeft: null,
      initialWidth: null,
      isAlreadyRegisteredPatient: false,
      isImageCaptured: false,
      isNew: true,
      isNewPatient: true,
      isOnEdit: false,
      patientId: null,
      status: 'initial',
      savedFingers: localStorage.getItem(FINGERS_DATA)
        ? JSON.parse(localStorage.getItem(FINGERS_DATA)).map(i => i.idFinger)
        : [],
      showDiscardButton: false,
      showDiscardFingerprintModal: false,
      showLeavingModal: false,
      showSuccessModal: false
    }
  },
  computed: {
    leavingModalButtons () {
      return [
        { text: this.$t('main.continue_registration'), template: 'white', handler: () => { this.showLeavingModal = false } },
        { text: this.$t('main.exit'), template: 'blue', handler: () => { this.hideAndLeavePage() } }
      ]
    },
    areThereOneFingerOfEachHand () {
      return (this.savedFingers.filter((i) => i < 5).length > 0 && this.savedFingers.filter((d) => d >= 5).length > 0)
    },
    isFingerSelected () {
      console.log('isFingerSelected', this.selectedFinger !== null)
      return this.selectedFinger !== null
    },
    isOnEdition () {
      return this.isOnEdit
    }
  },
  methods: {
    ...mapActions([
      'fetchPatientFingerprints',
      'deletePatient',
      'createPatient'
    ]),
    ...mapMutations([
      'resetPatientId',
      'setHandSelected',
      'resetHandSelected',
      'setFingerSelected',
      'resetFingerSelected'
    ]),
    onSelectHand (event, finger, hand) {
      this.selectedFinger = finger
      this.selectedHand = hand
      this.showDiscardButton = this.savedFingers.some(i => i === this.selectedFinger)
      this.calculateItemMarkPosition(event)
    },
    calculateItemMarkPosition (event) {
      if (event) {
        this.showItemMark = true
        const target = event.target || event
        if (this.$refs.itemMark) {
          this.$refs.itemMark.style.left = target.offsetLeft + PX_STR
          this.$refs.itemMark.style.width = target.offsetWidth + PX_STR
          this.initialLeft = target.offsetLeft
          this.initialWidth = target.offsetWidth
        }
      }
    },
    mouseOver (e) {
      this.showItemMark = true
      if (this.$refs.itemMark) {
        this.$refs.itemMark.style.left = e.target.offsetLeft + PX_STR
        this.$refs.itemMark.style.width = e.target.offsetWidth + PX_STR
      }
    },
    mouseOut (e) {
      if (this.$refs.itemMark) {
        this.$refs.itemMark.style.left = this.initialLeft + PX_STR
        this.$refs.itemMark.style.width = this.initialWidth + PX_STR
      }
    },
    mouseOutHands (e) {
      if (this.selectedFinger === null) {
        this.showItemMark = false
      }
    },
    onEdit () {
      if (this.selectedHand != null && this.selectedFinger != null) {
        this.setHandSelected(this.selectedHand)
        this.setFingerSelected(this.convertCorrectFingerTemp(this.selectedFinger))
        this.isOnEdit = true
      } else {
        this.resetHandSelected()
        this.resetFingerSelected()
      }
    },
    onDiscard () {
      this.showDiscardFingerprintModal = true
    },
    onSave () {
      this.getSerialNumber()
        .then((responseSN) => {
          const dataCreatePatient = {
            sensorSN: responseSN,
            data: JSON.parse(localStorage.getItem(FINGERS_DATA))
              ? JSON.parse(localStorage.getItem(FINGERS_DATA)).map(i => i.data)
              : []
          }
          this.createPatient(dataCreatePatient)
            .then((response) => {
              this.patientId = response[0].HHID
              this.sendDataToParentClient(this.patientId, this.isNew)
              this.showSuccessModal = true
            })
            .catch((e) => {
              if (e.response.data.errorCode === ERR_CODE_PATIENT_ALREADY_REGISTERED) {
                this.patientId = e.response.data.optionData
                this.isAlreadyRegisteredPatient = true
                this.showSuccessModal = true
              } else {
                throw Error(`${e.name}: Error while creating a patient`)
              }
            })
        })
    },
    onScan () {
      this.stopCapture()
      this.isImageCaptured = false
      this.captureFingerprint()
    },
    reloadSavedFingers () {
      this.savedFingers = localStorage.getItem(FINGERS_DATA)
        ? JSON.parse(localStorage.getItem(FINGERS_DATA)).map(i => i.idFinger)
        : []
    },
    convertCorrectFingerTemp (finger) {
      switch (finger) {
        case 9:
          return 0
        case 8:
          return 1
        case 7:
          return 2
        case 6:
          return 3
        case 5:
          return 4
        default:
          return finger
      }
    },
    closeSuccessModal () {
      this.canLeavePage = true
      this.showLeavingModal = false
      this.showSuccessModal = false
      this.resetPatientId()
      this.$router.push({
        name: MAIN_PAGE_ROUTE_NAME
      })
    },
    afterDiscard () {
      this.reloadSavedFingers()
      this.showDiscardButton = this.savedFingers.some(i => i === this.selectedFinger)
      this.closeDiscardModal()
    },
    closeDiscardModal () {
      this.showDiscardFingerprintModal = false
    },
    onCancel () {
      this.reloadSavedFingers()
      this.isOnEdit = false
    },
    goBack () {
      if (this.isOnEdit) {
        this.isOnEdit = false
      } else {
        this.goHome()
      }
    },
    goHome () {
      this.showConfirmMessage()
    },
    showConfirmMessage () {
      this.showLeavingModal = true
    },
    hideAndLeavePage () {
      this.showLeavingModal = false
      this.canLeavePage = true
      this.goToMainPage()
    },
    goToMainPage () {
      this.$router.push({ name: MAIN_PAGE_ROUTE_NAME })
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-stored-fingerprints-title {
  width: 400px;
}
.he-ui-stored-fingerprints-hands-wrapper {
  margin: 4rem 0;
  padding: 37px 53px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  @media (max-width: 1180px) {
    margin: 2rem 0;
  }
}

.he-ui-stored-fingerprints-hand-img {
  height: 10rem;
  min-width: 4rem;
  width: 8rem;
  cursor: pointer;
  @media (max-width: 1180px) {
    height: 4rem;
    min-width: 3rem;
    width: 3rem;
  }
  img {
    height: 100%;
    width: 100%;
  }
  +.he-ui-stored-fingerprints-hand-img {
    margin-left: 1rem;
  }
  &:nth-child(6n) {
    margin-left: 3rem;
  }
}
.he-ui-info-box {
  width: 473px;
}
.he-ui-item-mark {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.13rem;
  width: 50%;
  background: $blue-color;
  transition-property: left, width;
  transition-duration: .4s;
  transition-timing-function: linear;
}
.btn-disabled {
  cursor: default;
  opacity: 0.5;
}
</style>
