<template>
  <modal>
    <template #body>
      <p class="he-ui-error-modal-title text-uppercase">{{ title }}</p>
      <p class="he-ui-error-modal-subtitle">{{ subtitle }}:</p>
      <InfoBox
        class="he-ui-info-box mt-2 mb-5"
        status='indications'
        :hand="hand"
        :finger="finger"
      />
      <div class="d-flex flex-row gap-3">
        <Button
          template="white"
          class="mt-4"
          rounded
          uppercase
          :text="$t('main.cancel')"
          :onClick="onCancel"
        />
        <Button
          template="blue"
          class="mt-4"
          rounded
          uppercase
          :text="$t('main.discard')"
          :onClick="onDiscard"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import { FINGERS_DATA } from '@/constants/constants'
import Button from '@/components/MainButton'
import InfoBox from '@/components/InfoBox'
import Modal from '@/components/Modal.vue'

export default {
  components: {
    Button,
    InfoBox,
    Modal
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    hand: {
      type: Number,
      required: true
    },
    finger: {
      type: Number,
      required: true
    }
  },
  methods: {
    onCancel () {
      this.$emit('close')
    },
    onDiscard () {
      if (this.finger >= 0 && this.finger <= 9 && JSON.parse(localStorage.getItem(FINGERS_DATA))) {
        const newFingersArray = JSON.parse(localStorage.getItem(FINGERS_DATA)).filter(i => i.idFinger !== this.finger)
        localStorage.setItem(FINGERS_DATA, JSON.stringify(newFingersArray))
      }
      this.$emit('discard')
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-error-modal-title {
  font-weight: bold;
  font-size: 2.5rem;
}
.he-ui-error-modal-subtitle {
  font-size: 1.8rem;
  color: $grey-dark-color;
}
.he-ui-error-modal-image{
  width: 140px;
  height: 140px;
   img {
    max-width: 100%;
   }
}
</style>
